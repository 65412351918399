import { ContentfulContextProvider, DebugSection, ContentfulLink } from '@vp/mcs-react-tools'
import { SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import ExtraCouponsContent from './components/ExtraCouponsContent'
import { TrackingProvider } from './hooks/TrackingContext'

export interface AppProps {
  extraCouponsLabel: string
  extraCouponsContent: any
  debugData: any
  contentfulMetadata: any
}

const search = typeof window !== 'undefined' ? window.location.search : ''

export const Fragment = (props: AppProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.table
  ])

  const extraCouponsLabel = props.extraCouponsLabel
  const extraCouponsContent = props.extraCouponsContent
  return (
    <ContentfulContextProvider space={props.contentfulMetadata?.spaceId || ''}>
      <DebugSection
        contentfulMetadata={props.contentfulMetadata}
        availability={props.debugData}
        search={search}
      />
      <ContentfulLink id={props.contentfulMetadata?.contentful_id} />
      <TrackingProvider canonicalUrl='https://www.vistaprint.com/offers'>
        <ExtraCouponsContent extraCouponsLabel={extraCouponsLabel} extraCouponsContent={extraCouponsContent} />
      </TrackingProvider>
    </ContentfulContextProvider>
  )
}
