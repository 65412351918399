import { UncustomizableMarkup } from '@vp/swan'
import MarkdownWithTrackableLinks from './MarkdownTableWithTrackableLinks'
import { getExtraCouponsTrackingConfig } from '../Analytics/trackingConfig'

export interface TableProps {
  extraCouponsContent: any
}
const ExtraCouponsTable = ({ extraCouponsContent }: TableProps) => {
  return (
    <UncustomizableMarkup>
      <MarkdownWithTrackableLinks html={extraCouponsContent} getTrackingInfo={getExtraCouponsTrackingConfig} />
    </UncustomizableMarkup>
  )
}

export default ExtraCouponsTable
