import parse, { attributesToProps, domToReact } from 'html-react-parser'
import { useTrackingContext } from '../hooks/TrackingContext'
import { createOnClickTrackingHandler } from '../Analytics/trackingUtils'
import Attributes from 'html-react-parser/lib/attributes-to-props'

export interface MarkdownProps {
  html: any
  getTrackingInfo: any
}
const MarkdownWithTrackableLinks = ({ html, getTrackingInfo }: MarkdownProps) => {
  const { sourcePagePath } = useTrackingContext()

  const options = {
    replace: (domNode: { attribs: typeof Attributes | undefined; name: string; children: string | any[] }) => {
      const props = attributesToProps(domNode.attribs)

      if (domNode.name === 'a' && props.href) {
        const ctaValue = domNode.children.length > 0 && domNode.children[0].type === 'text' ? domNode.children[0].data : ''
        const trackingConfiguration = getTrackingInfo(sourcePagePath, props.href, ctaValue)
        const linkTrackingAttributes = trackingConfiguration ? trackingConfiguration.linkAttributes : {}

        return (
          <a {...props} onClick={createOnClickTrackingHandler(trackingConfiguration, props.href, false)} {...linkTrackingAttributes}>
            {domToReact(domNode.children)}
          </a>
        )
      }
    },
  }
  return html ? parse(html, options) : null
}

export default MarkdownWithTrackableLinks
