import { getTracking } from '@vp/tracking'
// import TrackingJS from "../../utils/tracking-mock"

const DELAY_BEFORE_NAVIGATING_IN_MS = 300

const createOnClickTrackingHandler = (trackingConfig: { eventName: any; properties: any }, href: string, openInNewTab: boolean) => {
  if (!trackingConfig) {
    return undefined
  }

  return (event: { preventDefault: () => any }) => {
    stopNavigation(event)
    track(trackingConfig)

    if (href) {
      setTimeout(() => navigate(href, openInNewTab), DELAY_BEFORE_NAVIGATING_IN_MS)
    }
  }
}

const stopNavigation = (event: { preventDefault: () => any }) => event.preventDefault()

const track = (trackingConfig: { eventName: any; properties: any }) => {
  const { eventName, properties } = trackingConfig
  const tracking = getTracking()
  if (tracking) {
    tracking.track(eventName, properties, {})
  } else {
    const options = { once: true }
    window.addEventListener(
      'trackingReady',
      () => {
        const tracking = getTracking()
        tracking && tracking.track(eventName, properties, {})
      },
      options
    )
  }
}

const navigate = (href: string, openLinkNewTab: any) => {
  if (openLinkNewTab) {
    window.open(href, '_blank', (rel = 'noopener noreferrer'))
  } else {
    window.location.href = href
  }
}

export { createOnClickTrackingHandler }
